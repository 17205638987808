import { Injectable } from '@angular/core';
import { AppProxy, HttpMethod } from "./app-proxy";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ServerResponse } from 'http';
import { Category, CategoryItem, ItemCategory, ListImages } from '../models/category';
import { OpenningHours, Restaurant, Search } from '../models/restaurant';
import { IgxExcelStyleFilterOperationsTemplateDirective } from 'igniteui-angular';
import { type } from 'os';
import { Location } from '@angular/common';

@Injectable()
export class BussinssService {
    categoryItemList:CategoryItem[]=[];

    restaurantCategorySearchList:CategoryItem[];
    categoryItemFavoriteList:CategoryItem[];
    public restaurant:Restaurant;
    public restaurant_:Restaurant;
    public categoryId:number;
    categores:Category[];
    categoryName:string;
    restaurantCategoryList:CategoryItem[];
    searchList:Search[];
    listImages:ListImages;
    categoryItemsList:CategoryItem[]=[];
    
    constructor(private proxy: AppProxy,private router: Router, private location: Location) { }

   getListImages()
   {
    let url= this.proxy.baseQAUrl+"Bussinss/getListImages";
    return new Observable(observer => {
    this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            this.listImages = res;
         let categoryItem1:CategoryItem=new CategoryItem;
         let categoryItem2:CategoryItem=new CategoryItem;
         let categoryItem3:CategoryItem=new CategoryItem;
         let categoryItem4:CategoryItem=new CategoryItem;
         this.categoryItemList=[];
         categoryItem1.ImageFile=this.listImages.Image1
         categoryItem1.Header=this.listImages.Url1;
         this.categoryItemList.push(categoryItem1);
         categoryItem2.ImageFile=this.listImages.Image2;
         categoryItem2.Header=this.listImages.Url2;
         this.categoryItemList.push(categoryItem2);
         categoryItem3.ImageFile=this.listImages.Image3;
         categoryItem3.Header=this.listImages.Url3;
         this.categoryItemList.push(categoryItem3);
         categoryItem4.ImageFile=this.listImages.Image4;
         categoryItem4.Header=this.listImages.Url4;
         this.categoryItemList.push(categoryItem4);
          {
            this.categores=[
              {
                "Name": "",
                "ID": 47,
                "categoryItems":this.categoryItemList,
                "isCheck":true,
                "categoryType":0,
              },
              // {    
              //   "Name": "מסעדות מומלצות",
              //   "ID": 1,
              //   "categoryItems":[],
              //   "isCheck":true,
              //   "categoryType":1,
              // },
              {
                "Name": "קטגוריות",
                "ID": 6,
                "categoryItems":[],
                "isCheck":true,
                "categoryType":2,
              },
              // {
              //   "Name": "עיסקיות צהריים",
              //   "ID": 9,
              //   "categoryItems":[],
              //   "isCheck":true,
              //   "categoryType":3,
              // },
              {
                "Name": "מתוקים",
                "ID": 8,
                "categoryItems":[],
                "isCheck":true,
                "categoryType":4,
              },
              {
                "Name": "מסעדות",
                "ID": 5,
                "categoryItems":[],
                "isCheck":true,
                "categoryType":5,
              },
              {
                "Name": "חנויות",
                "ID": 6,
                "categoryItems":[],
                "isCheck":true,
                "categoryType":6,
              },
              {
                "Name": "אטרקציות",
                "ID": 7,
                "categoryItems":[],
                "isCheck":true,
                "categoryType":7,
              },
            ]  
             if(localStorage.TEXT_SEARCH=="all") {
             observer.next(this.categoryItemList);
           }
           else     
            observer.next(this.categores)         
          }
        }
        },
      error =>  console.log('oops', error));
   })
   }

   getLastMenu() 
     {
      let url= this.proxy.baseQAUrl+"Bussinss/getLastMenu";
      return new Observable(observer => {
      this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
      .subscribe(
        result => {
            let res: any = result;
            if (res.error==false)
             {
              let resList: CategoryItem[] = res.list;
              this.categoryItemList = resList;
        
            if(localStorage.TEXT_SEARCH=="all") {
              observer.next(this.categoryItemList);
            }
            else{
              this.categores=[
                {
                  "Name": "",
                  "ID": 47,
                  "categoryItems":this.categoryItemList,
                  "isCheck":true,
                  "categoryType":0,
                },
                // {    
                //   "Name": "מסעדות מומלצות",
                //   "ID": 1,
                //   "categoryItems":[],
                //   "isCheck":true,
                //   "categoryType":1,
                // },
                {
                  "Name": "קטגוריות",
                  "ID": 6,
                  "categoryItems":[],
                  "isCheck":true,
                  "categoryType":2,
                },
                // {
                //   "Name": "עיסקיות צהריים",
                //   "ID": 9,
                //   "categoryItems":[],
                //   "isCheck":true,
                //   "categoryType":3,
                // },
                {
                  "Name": "מתוקים",
                  "ID": 8,
                  "categoryItems":[],
                  "isCheck":true,
                  "categoryType":4,
                },
                {
                  "Name": "מסעדות",
                  "ID": 5,
                  "categoryItems":[],
                  "isCheck":true,
                  "categoryType":5,
                },

                {
                  "Name": "חנויות",
                  "ID": 6,
                  "categoryItems":[],
                  "isCheck":true,
                  "categoryType":6,
                },
                {
                  "Name": "אטרקציות",
                  "ID": 6,
                  "categoryItems":[],
                  "isCheck":true,
                  "categoryType":7,
                },
              ]       
              observer.next(this.categores)         
            }
          }
          },
        error => console.log('oops', error));
     })}

getMainList(Type: number, Category: number, page: number,random:number)
{
  this.restaurantCategoryList = [];
  let url = this.proxy.baseQAUrl+"Bussinss/getMainList?Type=" + Type + "&Category=" + Category + "&page=" + page+"&random="+random;
  return new Observable(observer => {
    this.proxy.execute<ServerResponse>(url, HttpMethod.Get)
      .subscribe(
        result => {
          let res: any = result;
          if (res.error == false) {
            let resList: CategoryItem[] = res.list;
            this.restaurantCategoryList = resList;
            if (Type == 5 && Category>0 || localStorage.TEXT_SEARCH=="all") {
              if (this.restaurantCategoryList.length > 0) {
                if(localStorage.TEXT_SEARCH=="all")
                {
                  if(Type==1)
                  this.categoryName ="מסעדות מומלצות"
                  else if(Type==2)
                  this.categoryName ="קטגוריות"
                  // else if(Type==3)
                  // this.categoryName ="עיסקיות צהריים"
                  else if(Type==4)
                  this.categoryName ="מתוקים"
                  else if(Type==5)
                  this.categoryName ="קטגוריות"
                   else if(Type==6)
                   this.categoryName ="חנויות"
                   else if(Type==7)
                   this.categoryName ="אטרקציות"
                }
                else
                this.categoryName = this.restaurantCategoryList[0].Category;
              }
              observer.next(this.restaurantCategoryList)
            }
              else if (Type == 2)
              Type =3;
              else if(Type==4)
              Type=2; 
              else if(Type==6)
              Type=4; 
              else if(Type==7)
              Type=5; 
              if(this.categores!=null && this.categores[Type]!=null)
            this.categores[Type].categoryItems = resList;
            if (Type == 7)
              observer.next(this.categores);
          }
        },
        error => console.log('oops', error));
  })
}

getCategoryList(page: number){
  let url= this.proxy.baseQAUrl+"Bussinss/getCategoryList?page=" + page; 
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
             if(page==1)
            this.categoryItemsList=[];
            let resList: ItemCategory[] = res.list;
   if(resList.length>0)
   {
          for(let i=0;i<resList.length;i++)
          {
           let categoryItem=new CategoryItem();
           categoryItem.ID=resList[i].ID;
           categoryItem.Category=resList[i].Category;
           categoryItem.CountRate=resList[i].Count;
           categoryItem.ImageFile=resList[i].ImgFile;
           categoryItem.CategoryEn=resList[i].CategoryEn;
           this.categoryItemsList.push(categoryItem);
          }
        }
            if(localStorage.TEXT_SEARCH=="all" && this.categoryItemList!=null) {
              observer.next(this.categoryItemsList);
            }
            else{
              // this.categores[1].categoryItems=this.categoryItemsList;
              if(resList!=null && resList.length>0)
              {
              page=Number(page)+1;      
                this.getCategoryList(page).subscribe((res:any[])=>{
                  // for(let i=0;i<res.length;i++)
                  // {
                  // this.categoryItemsList.push(res[i]);
                  // }
              });
            }
            else if(page>1)
            this.categores[1].categoryItems=this.categoryItemsList;
              observer.next(this.categores);
            }
          }
          else this.proxy.showError("רשימת הקטגוריות ריקה","");
        },
      error => console.log('oops', error));
  });
}

getRestaurantDetails_(id:number)
{
  let url= this.proxy.baseQAUrl+"Bussinss/getRestaurantDetails?BusinessID="+id;
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: Restaurant = res;
            this.restaurant_=resList;
            observer.next(this.restaurant_);
          }
          else{
           // localStorage.ID=localStorage.ID_CATEGORY;
           // this.proxy.showError("שגיאה בקבלת פרטים ","");
          }
        },
      error => console.log('oops', error));
  });
}

getRestaurantDetails(id:number,IsMeaty:boolean,IsKasher:boolean,IsAsian:boolean,Category:String,MenuID:number)
{
  let url;
  if(Category!=null && Category!="" )
   url= this.proxy.baseQAUrl+"Bussinss/getRestaurantDetails?BusinessID="+id+"&Category="+Category+"&MenuID="+MenuID; 
   else
   url= this.proxy.baseQAUrl+"Bussinss/getRestaurantDetails?BusinessID="+id+"&MenuID="+MenuID; 
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: Restaurant = res;
           
            this.restaurant=resList;
            for(let i=0;i<this.restaurant.categoryList.length;i++)
            {
              if(this.restaurant.categoryList[i].Category==Category)
                this.restaurant.categoryList[i].isCheck=1;
              else
              this.restaurant.categoryList[i].isCheck=0;

            }
            observer.next(this.restaurant);
          }
          else{
           // localStorage.ID=localStorage.ID_CATEGORY;
           observer.next(null);
           // this.proxy.showError("אין כזו מסעדה פעילה","");
          }
        },
      error => console.log('oops', error));
  });
}

getOpenningHours(id:number)
{
  this.restaurantCategoryList = [];
  let url = this.proxy.baseQAUrl+"Bussinss/getOpenningHours?BusinessID="+id;
  return new Observable(observer => {
    this.proxy.execute<ServerResponse>(url, HttpMethod.Get)
      .subscribe(
        result => {
          let res: any = result;
          if (res.error == false) {
            let resList: OpenningHours[] = res.list;
            observer.next(resList);
          }
          },
          error => console.log('oops', error));
        });
}
getOpenningHours2(id:number)
{
  this.restaurantCategoryList = [];
  let url = this.proxy.baseQAUrl+"Bussinss/getOpenningHours?BusinessID="+id;
  return new Observable(observer => {
    this.proxy.execute<ServerResponse>(url, HttpMethod.Get)
      .subscribe(
        result => {
          let res: any = result;
          if (res.error == false) {
            let resisOpen: boolean = Boolean(res.IsOpen);
            observer.next(resisOpen);
          }
          },
          error => console.log('oops', error));
        });
}
getFavoriteBusiness()
{
  let url= this.proxy.baseQAUrl+"Bussinss/getFavoriteBusiness"; 
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: CategoryItem[] = res.list;
            this.categoryItemFavoriteList=resList;
           
            observer.next(this.categoryItemFavoriteList);
            if(this.categoryItemFavoriteList.length==0)
             this.proxy.showError("אין לך בתי עסק שמורים","");
            
          //  this.router.navigate(['../reservedRestaurants']);
          }
          else this.proxy.showError("אין לך בתי עסק שמורים","");
        //  this.garageOpeningSubj.next(this.categoryItemList);
        },
      error => console.log('oops', error));
  });
}

setFavoriteBusiness(id:number)
{
  let url= this.proxy.baseQAUrl+"Bussinss/setFavoriteBusiness?ID="+id; 
  return new Observable(observer => {
  this.proxy.executePost<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {
            let resList: ServerResponse[] = res.responseMessage;
            observer.next();
           // this.categoryItemFavoriteList=resList;
          }
          else this.proxy.showError("בעיה בעידכון למועדפים","");
        //  this.garageOpeningSubj.next(this.categoryItemList);
        },
      error => console.log('oops', error));
  });
 }

 getBusinessMenuBySearch(BusinessID:number,Search:string,page: number)
 {
  if(BusinessID!=undefined)
  {
  let url= this.proxy.baseQAUrl+"Bussinss/getBusinessMenuBySearch?BusinessID="+BusinessID+"&Search="+Search + "&page=" + page;
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {   
            let resList: CategoryItem[] = res.list;
             // if(resList.length>0)
              {
                this.categoryName=Search;
                this.restaurantCategoryList=[];
                this.restaurantCategoryList = resList;
               // if(this.restaurantCategoryList.length>0)
                observer.next(this.restaurantCategoryList);
              }
           //   else 
           // {
              
             // if(page==1)
            // this.proxy.showError("לא נמצאו מנות בחיפוש זה","");
             // this.router.navigate(["../search"]);
           // }
          }
        },
      error => console.log('oops', error)); 
  });
}
 }

 getMenuBySearch(Search:string,page: number,header:boolean)
 {
  //console.log('getMenuBySearch', Search);
  let url= this.proxy.baseQAUrl+"Bussinss/getMenuBySearch?Search="+Search + "&page=" + page;
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {   
            let resList: CategoryItem[] = res.list;
              if(resList.length>0)
              {
                this.categoryName=Search;
                this.restaurantCategoryList=[];
                this.restaurantCategoryList = resList;
                if(this.restaurantCategoryList.length>0)
                observer.next(this.restaurantCategoryList);
              }
              else 
            {
              observer.next(resList);
              // if(page==1 && header==true && localStorage.NoSearch=="false")
              // {
              //   header=false;
              //   localStorage.NoSearch="true";
      
              //  window.history.back();
              //  window.history.forward();
          
              // }
            }
          }
        },
      error => console.log('oops', error)); 
  });
 }

 getGlobalSearch()
 {
  let url= this.proxy.baseQAUrl+"Bussinss/getGlobalSearch"; 
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {   
            let resList: Search[] = res.list;
              if(resList.length>0)
              {
                this.searchList=[];
                this.searchList = resList;
                if(this.searchList.length>0)
                observer.next(this.searchList);
              }
              else 
            {
              this.proxy.showError("אין רשימת חיפושים אפשרית","");
            }
          }
        },
      error => console.log('oops', error)); 
  });
 }

 setGlobalSearch(search:string)
 {
  let url= this.proxy.baseQAUrl+"Bussinss/setGlobalSearch?search="+search; 
  return new Observable(observer => {
  this.proxy.execute<ServerResponse>(url,HttpMethod.Post)
    .subscribe(
      result => {
          let res: any = result;
          if (res.error==false)
           {   
                observer.next(res);
              }
        },
      error => console.log('oops', error)); 
  });
 }

getBusinessEnName(BusinessID:number)
{
let url= this.proxy.baseQAUrl+"Bussinss/getBusinessEnName?BusinessID="+BusinessID; 
return new Observable(observer => {
this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
  .subscribe(
    result => {
        let res: any = result;
        if (res.error==false)
         {   
          observer.next(res.responseMessage);
         }
      },
    error => console.log('oops', error)); 
});
}

getBusinessIDByEnName(BusinessEnName:string)
{
let url= this.proxy.baseQAUrl+"Bussinss/getBusinessIDByEnName?BusinessEnName="+BusinessEnName; 
return new Observable(observer => {
this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
  .subscribe(
    result => {
        let res: any = result;
        if (res.error==false)
         {   
          observer.next(res.responseMessage);
         }
      },
    error => console.log('oops', error)); 
});
}

getCategoryIDByEnName(CategoryEnName:string)
{
let url= this.proxy.baseQAUrl+"Bussinss/getCategoryIDByEnName?CategoryEnName="+CategoryEnName; 
return new Observable(observer => {
this.proxy.execute<ServerResponse>(url,HttpMethod.Get)
  .subscribe(
    result => {
        let res: any = result;
        if (res.error==false)
         {   
          observer.next(res.responseMessage);
         }
         else
         observer.next(null);
      },
    error => console.log('oops', error)); 
});
}
}