import { OrderAdding } from "./order";

export class Restaurant{
    ID:number;
    runtimeDate:string;
    Name:string;
    NameEn:string;
    Header:string;
    ImageFile:string;
    BigImage:string;
    LongImage:string;
    Rate:number;
    CountRate:number;
    IsPicking:number;
    IsDelivery:number;
    IsPlaceSetting:number;
    Phone:string;
    Address:string;
    Text:string;
    Kasher:number;
    Kashrut:string;
    IsMeaty:number;
    IsAsian:number;
    IsFevorite:number;
    IsOpen:number;
    IsFreeShipping:number;
    MinimumOrder:string;
    listSetting:Setting[]=[];
    error:boolean;
    responseMessage:string;
    MenuList:RestaurantItem[]=[];
    categoryList:CategoryRestaurant[]=[];
    ListDay:RestaurantDays[]=[];
}

export class OpenningHours{
    Day: string;
    DayTime:string;
}

export class RestaurantDays{
    Day:string;
    DayTime:string;
}

export class CategoryRestaurant{
    ID:number;
    Category:string;
    isCheck:number=0;
}

export class RestaurantItem{
    ID:number;
    Name:string;
    MenuText:string;
    Description:string;
    Image:string;
    Price:number;
    Rate:number;
    CountRate:number;
    Amount:number;
    IsAddition:number;
    IsAdditionRequired:number;
    ChangeFood:ChangeFood[];  
}

export class ChangeFood
{
    ID:number;
    Name:string; 
    Text:string 
    toppings:OrderAdding[];  
}

export class OrderAdditon
{
    Type:string;
    Count:number;
    listSelect:ToppingsItem[];
}

export class ToppingsItem
{
    select:boolean;
    Text:string;
    listA:ToppingsItemFood[];
}

export class ToppingsItemFood
{
    ID:number;
    AdditionID:number;
    Food:string;
    Price:string;
    PriceText:string;
    select:boolean;
}

export class Setting
{
Text:string;
Show:number;
}

export class Search{
    Sherch:string;
}