import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { OneTimeOrderService } from 'src/app/services/oneTimeOrder.service';
import { OrdersService } from 'src/app/services/order-service';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-popup-enter2',
  templateUrl: './popup-enter2.component.html',
  styleUrls: ['./popup-enter2.component.scss']
})
export class PopupEnter2Component implements OnInit {
  innerHeight: any;
  innerWidth:any;
  @Output()state: EventEmitter<number> = new EventEmitter<number>();

  constructor(private matDialog:MatDialog ,private orderService: OrdersService ,private oneTimeOrder: OneTimeOrderService ,private router:Router, private dataService:DataService) { }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  signUp(){
    sessionStorage.dialog="false"
    localStorage.oneTimeOrderOrOrder="order"; 
    this.orderService.oneTimeOrderOrOrder="order"; 
    this.matDialog.closeAll();
     this.openDialog();
  }

   noSign(){
    sessionStorage.dialog="false"
    this.matDialog.closeAll();
    this.oneTimeOrder.createOneTimeUser().subscribe((res:any)=>{
      localStorage.oneTimeOrderOrOrder="oneTimeOrder"; 
      this.orderService.oneTimeOrderOrOrder="oneTimeOrder";
      //אולי למחוק עוד?? 15.10.24
      this.dataService.token.next('oneTimeOrder');
      this.dataService.state.next("התחבר");
      this.state.emit();
    //  this.matDialog.closeAll();
    //  if(sessionStorage.deeplink=='' || sessionStorage.deeplink=='null' || sessionStorage.deeplink=='undefined')
    //  this.router.navigate(["../mainHome"]);
     // else
      //this.matDialog.closeAll();
      {
         let a=location.pathname;
         let url: string =  a
         this.router.navigateByUrl(url);
        // this.matDialog.closeAll();
        //this.matDialog.closeAll();
 
      }
    });
   }

  openDialog() {
    this.matDialog.closeAll();
    this.matDialog.open(LoginComponent, { disableClose: true });
  }
}
