
<div class="container">
  
  <div class="body" style="font-family: OpenSansHebrewBold">
      <!-- <iframe style=" min-height: 640px; height: 100% ; width: 100%; " width="100%" height="100%" frameBorder="0" [src]="urlSafe" Redirected="onHtmlDataLoad($event)"></iframe> -->
      <div *ngIf="showsButton == false" (click)="backPress()" class="buttonx" style="font-size: 25px;cursor: pointer;  ">x</div>
        <iframe id="myIframe"
         *ngIf="showsIframe"
         style="height: 630px ;
          width: 100%; "
           width="100%" 
           height="100%"
            frameBorder="0" [src]="urlSafe" (load)="onHtmlDataLoad($event)" 
            scrolling="yes" 
            javaScriptEnabled={true}
            allow="payments" 
           >
         
        </iframe>
        <div class="body" style="font-family: OpenSansHebrew;  display: flex;
       margin-top: 30%;
        justify-content: center;  flex-flow: column;">
        <span style="margin-right: 5px; font-size: 40px;">{{title}}</span>
        <span style="margin-right: 5px; font-size: 20px; font-weight: normal; margin-top: 7%;">{{myText}}</span>
      </div>
        <div *ngIf="showsButton" (click)="backPress()" class="button" style="cursor: pointer; ">להמשך</div>
      </div>
     
    </div>

